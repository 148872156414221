import {createAsyncThunk} from '@reduxjs/toolkit';
import {ErrorResponse} from '../../models/response/ErrorResponse';
import $api from '../../http';
import {AxiosError} from 'axios';
import {TSupplier, TSupplierEditRequest} from './types';

export const fetchSuppliers = createAsyncThunk<TSupplier[], void, { rejectValue: ErrorResponse }>('suppliers/fetchSuppliers', async (_, {rejectWithValue}) => {
    try {
        const response = await $api.get<{ data: TSupplier[] }>('/api/supplier');
        return response.data.data;
    } catch (e) {
        const err = e as AxiosError<ErrorResponse>;
        return rejectWithValue(err.response?.data!);
    }
});

export const editSupplier = createAsyncThunk<TSupplier, TSupplierEditRequest, { rejectValue: ErrorResponse }>('suppliers/editSupplier', async (arg, {rejectWithValue}) => {
    try {
        const response = await $api.put<{ data: TSupplier | boolean }>('/api/supplier', {...arg});
        if (typeof response.data.data === 'boolean')
            return rejectWithValue({errors: [], message: 'Произошла ошибка, повторите попытку позже'});
        return response.data.data;
    } catch (e) {
        const err = e as AxiosError<ErrorResponse>;
        return rejectWithValue(err.response?.data!);
    }
});