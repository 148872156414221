import styles from './Header.module.scss';
import {Link, NavLink, useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import Select from '../Select';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {ReactComponent as UserSVG} from '../../../assets/svg/user.svg';
import {ReactComponent as CartSVG} from '../../../assets/svg/cart.svg';
import {ReactComponent as LogoSVG} from '../../../assets/svg/logo.svg';
import {useTranslation} from 'react-i18next';
import Cookies from 'cookies-ts';
import AuthModal from '../AuthModal';
import {useAppDispatch, useAppSelector} from '../../../store/hook';
import {setCurrency} from '../../../store/currency/slice';
import {ECurrency} from '../../../store/currency/types';
import cn from 'classnames';
import {toast} from 'react-toastify';
import SearchFiled from '../../SearchFiled';
import getCurrency from '../../../utils/getCurrency';
import {TRates} from "../../admin/CurrencyModal/types";
import $api from "../../../http";

const cookies = new Cookies();

const Header = () => {
    const {isAuth, user: {isAdmin, language: userLanguage}} = useAppSelector(state => state.userSlice);
    const dispatch = useAppDispatch();
    const {current} = useAppSelector(state => state.currency);
    const [currencies, setCurrencies] = useState<TRates | undefined>();
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const searchRef = useRef<HTMLInputElement>(null);

    const cartCount = useAppSelector(state => state.cartSlice.cart.length);

    // const [analogs, setAnalogs] = useState(searchParams.get('analogs') === 'true');
    const [language, setLanguage] = useState(cookies.get('i18next') || '');
    const [searchValue, setSearchValue] = useState(searchParams.get('q') || '');
    const [modal, setModal] = useState(false);

    const languageHandler = useCallback((value: string) => {
        i18n.changeLanguage(value).then(() => setLanguage(value));
    }, [i18n]);

    useEffect(() => {
        if (userLanguage)
            languageHandler(userLanguage)
    }, [i18n, languageHandler, userLanguage])

    const currencyHandler = useCallback((selected: string) => {
        const s = selected as ECurrency;
        dispatch(setCurrency({currency: s}));
        cookies.set('currency', getCurrency(s), {expires: '1d'});
    }, [dispatch]);

    const searchHandler = useCallback(() => {
            if (searchValue.trim().length > 0) {
                const query = `search?q=${searchValue
                    .replaceAll(' ', '')
                    .replaceAll('.', '')
                    .replaceAll('/', '')
                    .replaceAll('-', '')
                    .trim()}`;
                navigate(query);
            }
        },
        // [searchValue, analogs, navigate]
        [searchValue, navigate]
    );

    const modalHandler = useCallback(() => setModal(false), []);
    const authHandler = useCallback(() => {
        toast.warning(t('toasts.auth.need'), {toastId: 'toasts_auth'});
        setModal(true);
    }, [t]);

    const links = [
        {
            title: t('header.contacts'),
            href: '',
        },
        {
            title: t('header.orders'),
            href: '/orders',
            authRequire: true,
        },
        {
            title: t('header.shipments'),
            href: '/shipments',
            authRequire: true,
        },
        {
            title: t('header.finances'),
            href: '/finances',
            authRequire: true,
        },
        // {
        //     title: t('header.messages'),
        //     href: '/messages',
        //     authRequire: true,
        // },
        // {
        //     title: t('header.requests'),
        //     href: '/requests',
        //     authRequire: true,
        // },
        // {
        //     title: t('header.news'),
        //     href: '/news',
        // },
        // {
        //     title: t('header.faq'),
        //     href: '/faq',
        // },
    ];

    useEffect(() => {
        if (location.pathname !== '/search') {
            setSearchValue('');
            // setAnalogs(false);
        }
        const openAuth: boolean = location.state?.openAuth;
        const openForgot: boolean = location.state?.isOpenForgot
        const langChange: "Ru" | "Ua" | undefined = location.state?.lang
        if (openAuth) {
            setModal(true);
            window.history.replaceState({}, document.title);
        } else if (openForgot) {
            setModal(true)
            window.history.replaceState({}, document.title);
        } else if (langChange) {
            languageHandler(langChange)
            window.history.replaceState({}, document.title);
        }

    }, [languageHandler, location]);


    useEffect(() => {
        const keyHandler = (e: KeyboardEvent) => {
            const target = e.target as { tagName?: string };
            if (target.tagName !== 'INPUT' && target.tagName !== 'TEXTAREA' && searchRef.current && e.code === 'Slash') {
                e.preventDefault();
                searchRef.current.focus();
            }
        };

        document.addEventListener('keydown', keyHandler);
        const cur = cookies.get('currency');
        if (!cur) {
            cookies.set('currency', getCurrency(current), {expires: '1d'});
        }

        return () => {
            document.removeEventListener('keydown', keyHandler);
        };
    }, [current]);

    useEffect(() => {
        $api.get<{ rates: TRates }>('/api/exchange')
            .then(res => setCurrencies(res.data.rates))
            .catch(err => console.log('Failed to fetch exchange rates:', err))
    }, []);

    return (
        <>
            <header className={styles.header}>
                <nav>
                    <div className="container">
                        <ul className={styles.linksList}>
                            {links.map(link => (
                                <li key={link.title}>
                                    {link.authRequire && !isAuth ? (
                                        <div onClick={authHandler}>{link.title}</div>
                                    ) : (
                                        <NavLink
                                            className={({isActive}) => isActive ? styles.active : ''}
                                            to={link.href}
                                        >
                                            {link.title}
                                        </NavLink>
                                    )}
                                </li>
                            ))}
                            {isAdmin && <li><NavLink to="/admin/">{t('header.admin')}</NavLink></li>}
                        </ul>
                        <div className={styles.selectList}>
                            {currencies && <div className={styles.currencies}>
                                <span>1{ECurrency.USD} = {currencies.usd.toFixed(2)}{ECurrency.UAH}<span style={{fontWeight: 400, margin: '0 7px', borderRight: '1px solid #000'}}></span>1{ECurrency.EUR} = {currencies.eur.toFixed(2)}{ECurrency.USD}</span>
                            </div>}
                            <Select items={[ECurrency.UAH, ECurrency.USD, ECurrency.EUR]} selected={current}
                                    setSelected={currencyHandler}/>
                            <Select items={['Ru', 'Ua']} selected={language} setSelected={languageHandler}/>
                        </div>
                    </div>
                </nav>
                <div className={styles.headerContainer}>
                    <div className={styles.logo}><Link to="/"><LogoSVG/></Link></div>
                    <SearchFiled
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        onSearch={searchHandler}
                        // isHeader
                        // analogs={analogs}
                        // setAnalogs={setAnalogs}
                        searchRef={searchRef}
                        placeholder={t('header.search_placeholder') || ''}
                    />
                    <div className={styles.button} onClick={() => !isAuth && setModal(true)}>
                        {isAuth ? (
                            <NavLink to="/cabinet" className={({isActive}) => isActive ? styles.active : ''}>
                                <UserSVG/>
                                <span>{t('header.cabinet')}</span>
                            </NavLink>
                        ) : (
                            <div>
                                <UserSVG/>
                                <span>{t('header.cabinet_login')}</span>
                            </div>
                        )}
                    </div>
                    <div className={styles.button}>
                        <NavLink
                            to="/cart"
                            data-count={cartCount}
                            className={({isActive}) => isActive ? cn(styles.active, {[styles.cartBtn]: cartCount > 0}) : cn({[styles.cartBtn]: cartCount > 0})}
                        >
                            <CartSVG/>
                            <span>{t('header.cart')}</span>
                        </NavLink>
                    </div>
                </div>
            </header>
            <AuthModal open={modal} onClose={modalHandler} initTab={location.state?.isOpenForgot ? 2 : 0}/>
        </>
    );
};

export default Header;
