import React, {FC, useEffect, useState} from 'react';
import styles from './PasswordRequirements.module.scss';
import {useTranslation} from "react-i18next";
import {ReactComponent as XMarkSVG} from "../../../assets/svg/xMark.svg";
import {ReactComponent as SuccessSVG} from "../../../assets/svg/checked.svg";
import {PasswordRequirementsProps, TPassReq} from "./types";
import cn from "classnames";

const PasswordRequirements: FC<PasswordRequirementsProps> = ({value, className, isCompare, value2}) => {
    const {t} = useTranslation()
    const [state, setState] = useState<TPassReq>({
        isDigit: false,
        isLength: false,
        isSpecSymbol: false,
        isUppercase: false,
        isCompare: false,
    })

    useEffect(() => {
        if (value)
            setState({
                isLength: value.length > 7,
                isUppercase: /^(?=.*[A-Z])/.test(value),
                isSpecSymbol: /^(?=.*[!@#$%^&*])/.test(value),
                isDigit: /^(?=.*\d)/.test(value),
                isCompare: value === value2
            })
        else
            setState({
                isDigit: false,
                isLength: false,
                isSpecSymbol: false,
                isUppercase: false,
                isCompare: false
            })
    }, [value, value2])


    return (
        <div className={cn(styles.req, className)}>
            <div>
                {state.isLength ? <SuccessSVG/> : <XMarkSVG/>}
                <span>{t('cabinet.security.new_pass.requirements.length')}</span>
            </div>
            <div>
                {state.isSpecSymbol ? <SuccessSVG/> : <XMarkSVG/>}
                <span>{t('cabinet.security.new_pass.requirements.spec')}</span>
            </div>
            <div>
                {state.isDigit ? <SuccessSVG/> : <XMarkSVG/>}
                <span>{t('cabinet.security.new_pass.requirements.digit')}</span>
            </div>
            <div>
                {state.isUppercase ? <SuccessSVG/> : <XMarkSVG/>}
                <span>{t('cabinet.security.new_pass.requirements.uppercase')}</span>
            </div>
            {isCompare && <div>
                {state.isCompare ? <SuccessSVG/> : <XMarkSVG/>}
                <span>{t('cabinet.security.new_pass.requirements.compare')}</span>
            </div>}
        </div>
    );
};

export default PasswordRequirements;
