enum EOrderStatus {
    NEW = 'new',
    IN_PROGRESS = 'in_progress',
    PURCHASED = 'purchased',
    IN_TRANSIT = 'in_transit',
    DELAYED = 'delayed',
    DONE = 'done',
    REFUSED = 'refused',
    STOCK = 'stock',
    REMOVED = 'removed',
    REFUND = 'refund',
    SHIPMENT_READY = 'shipment_ready'
}

export default EOrderStatus;
