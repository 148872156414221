import {createSlice} from '@reduxjs/toolkit';
import EStatus from '../../utils/EStatus';
import {TUsersAdminState} from './types';
import {deleteUserAdminBuilder, editUsersAdminBuilder, fetchUsersAdminBuilder} from './builders';

const initialState: TUsersAdminState = {
    status: EStatus.IDLE,
    users: [],
};

const usersAdminSlice = createSlice({
    name: 'usersAdmin',
    initialState,
    reducers: {},
    extraReducers: builder => {
        fetchUsersAdminBuilder(builder);
        editUsersAdminBuilder(builder);
        deleteUserAdminBuilder(builder);
    },
});

// export const {} = usersAdminSlice.actions

export default usersAdminSlice.reducer;
