import {FC} from 'react';
import {Helmet} from 'react-helmet-async';
import {TSEOProps} from './types';

const SEO: FC<TSEOProps> = ({title}) => {
    return (
        <Helmet>
            { /* Standard metadata tags */}
            <title>{title ? `${title} | Автомир` : 'Автомир'}</title>
            {/*<meta name='description' content={description} />*/}
            { /* End standard metadata tags */}
            { /* Facebook tags */}
            {/*<meta property="og:type" content={type} />*/}
            {/*<meta property="og:title" content={title} />*/}
            {/*<meta property="og:description" content={description} />*/}
            { /* End Facebook tags */}
            { /* Twitter tags */}
            {/*<meta name="twitter:creator" content={name} />}*/}
            {/*<meta name="twitter:card" content={type} />*/}
            {/*<meta name="twitter:title" content={title} />*/}
            {/*<meta name="twitter:description" content={description} />*/}
            { /* End Twitter tags */}
        </Helmet>
    );
};

export default SEO;