import {createAsyncThunk} from '@reduxjs/toolkit';
import {IOrder, IOrderRequest} from './types';
import $api from '../../http';
import {AxiosError} from 'axios';
import {ErrorResponse} from '../../models/response/ErrorResponse';

export const fetchOrders = createAsyncThunk<IOrder[], void, { rejectValue: ErrorResponse }>('orders/fetchOrders', async (_, {rejectWithValue}) => {
    try {
        const response = await $api.get<{orders: IOrder[]}>('/api/order');
        return response.data.orders;
    } catch (e) {
        const err = e as AxiosError<ErrorResponse>;
        return rejectWithValue(err.response?.data!);
    }
});
export const setOrderRequest = createAsyncThunk<IOrder, IOrderRequest, { rejectValue: ErrorResponse }>('orders/setOrderRequest', async (order, {rejectWithValue}) => {
    try {
        const response = await $api.post<{order: IOrder}>('/api/order', order);
        return response.data.order;
    } catch (e) {
        const err = e as AxiosError<ErrorResponse>;
        return rejectWithValue(err.response?.data!);
    }
});