import React, {FC, KeyboardEvent, ChangeEvent, useEffect} from 'react';
import styles from './SearchField.module.scss';
import {SearchFieldProps} from './types';
import {ReactComponent as SearchSVG} from '../../assets/svg/search.svg';
import {ReactComponent as CheckedSVG} from '../../assets/svg/checkbox-checked.svg';
import {ReactComponent as UncheckedSVG} from '../../assets/svg/checkbox-unchecked.svg';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';

const SearchField: FC<SearchFieldProps> = ({
                                               placeholder,
                                               searchValue,
                                               setSearchValue,
                                               onSearch,
                                               isHeader = false,
                                               analogs,
                                               setAnalogs,
                                               name,
                                               className,
                                               isResettable,
                                               searchRef,
                                               ...props
                                           }) => {
    const {t} = useTranslation();

    const keyDownHandler = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            onSearch();
        }
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    };

    useEffect(() => {
        if (searchValue === '' && isResettable)
            onSearch()
    }, [searchValue]);

    return (
        <div className={cn(styles.search, className)} {...props}>
            <input
                type="text"
                placeholder={placeholder}
                onKeyDown={keyDownHandler}
                ref={searchRef}
                autoComplete="on"
                name={name ?? 'SearchForm[code]'}
                id={name ?? 'searchform-code'}
                onChange={handleChange}
                value={searchValue}
            />
            <button type="button" onClick={onSearch}>
                <SearchSVG/>
            </button>
            {isHeader && (
                <div className={styles.subtitle} onClick={() => setAnalogs?.(prevState => !prevState)}>
                    <span>{t('header.search_params')}</span>
                    {analogs ? <CheckedSVG/> : <UncheckedSVG/>}
                </div>
            )}
        </div>
    );
};

export default SearchField;
