import React, {FC, useCallback, useEffect, useState} from 'react';
import styles from './Checkout.module.scss';
import {useTranslation} from 'react-i18next';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import cn from 'classnames';
import {useForm} from 'react-hook-form';
import PersonalData from './components/PersonalData';
import ShipmentData from './components/ShipmentData';
import ConfirmData from './components/ConfirmData';
import {TPaymentDataForm, TPersonalDataForm} from './types';
import {useAppSelector} from '../../../store/hook';
import {TUserShipmentData} from '../../../models/IUser';
import {useLocation, useNavigate} from 'react-router-dom';
import {TCartItem} from '../../../store/cart/types';
import SEO from '../../../components/SEO';

const Checkout: FC = () => {
    const {t} = useTranslation();
    const {user, shipment} = useAppSelector(state => state.userSlice);

    const [selectedShipment, setSelectedShipment] = useState<TUserShipmentData>()
    const [tabIndex, setTabIndex] = useState(0);
    const [data, setData] = useState<{
        personal?: TPersonalDataForm,
        shipment?: TUserShipmentData,
        payment?: TPaymentDataForm
    }>({payment: {paymentType: 'cash'}});

    const location = useLocation();
    const navigate = useNavigate();

    const personalDataForm = useForm<TPersonalDataForm>({defaultValues: data?.personal});
    const shipmentDataForm = useForm<TUserShipmentData>({defaultValues: data?.shipment});
    // const paymentDataForm = useForm<TPaymentDataForm>({defaultValues: data?.payment});

    const onSubmitPersonalDataForm = useCallback((tabIndex: number, data?: TPersonalDataForm) => {
        setData(prevState => ({
            ...prevState,
            personal: data,
        }));
        setTabIndex(tabIndex);
    }, []);
    const onSubmitShipmentDataForm = useCallback((tabIndex: number, data?: TUserShipmentData) => {
        if (data)
            setData(prevState => ({
                ...prevState,
                shipment: data,
            }));
        setTabIndex(tabIndex);
    }, []);
    // const onSubmitPaymentDataForm = (tabIndex: number, data?: TPaymentDataForm) => {
    //     setData(prevState => ({
    //         ...prevState,
    //         payment: data,
    //     }));
    //     setTabIndex(tabIndex);
    // };

    const getFormValues = useCallback((): TPersonalDataForm & TUserShipmentData & TPaymentDataForm | undefined => {
        if (data && data.payment && data.shipment && data.personal)
            return {...data.payment, ...data.shipment, ...data.personal};
    }, [data]);

    useEffect(() => {
        if (selectedShipment && selectedShipment.firstName && selectedShipment.lastName && selectedShipment.phone && selectedShipment.region && selectedShipment.city) {
            setData({
                shipment: selectedShipment,
                personal: {
                    firstName: selectedShipment.firstName,
                    lastName: selectedShipment.lastName,
                    phone: selectedShipment.phone,
                    middleName: selectedShipment.middleName,
                    email: user.email
                },
                payment: {paymentType: 'cash'}
            })
            setTabIndex(2)
        }
    }, [selectedShipment, user]);

    useEffect(() => {
        if (shipment.length > 0) {
            setSelectedShipment(shipment[0])
            // setData(prevState => ({...prevState, shipment: shipment[0]}))
        }
    }, [shipment])

    useEffect(() => {
        const items = location.state?.items as TCartItem[] | undefined;

        if (!items) {
            navigate('/cart');
            window.history.replaceState({}, document.title);
        }
    }, [location.state?.items, navigate]);
    return (
        <div className={styles.checkout}>
            <SEO title={t('checkout.title')}/>
            <h1 className="title">{t('checkout.title')}</h1>
            <Tabs className={styles.tabs} selectedTabPanelClassName={styles.selected}
                  onSelect={(index) => setTabIndex(index)} selectedIndex={tabIndex}>
                <TabList className={styles.tabList}>
                    <Tab className={cn(styles.tab, {[styles.done]: tabIndex > 0})} data-index={1}
                         selectedClassName={styles.selected}>{t('cabinet.personal_data.title')}</Tab>
                    <Tab className={cn(styles.tab, {[styles.done]: tabIndex > 1})} data-index={2}
                         disabled={tabIndex === 0}
                         selectedClassName={styles.selected}>{t('checkout.shipment.title')}</Tab>
                    {/*<Tab className={cn(styles.tab, {[styles.done]: tabIndex > 2})} data-index={3}*/}
                    {/*     disabled={tabIndex <= 1}*/}
                    {/*     selectedClassName={styles.selected}>{t('checkout.payment.title')}</Tab>*/}
                    <Tab className={cn(styles.tab, {[styles.done]: tabIndex > 2})} data-index={3}
                         disabled={tabIndex <= 1}
                         selectedClassName={styles.selected}>{t('checkout.verify_order.title')}</Tab>
                </TabList>
                <TabPanel className={styles.tabPanel}>
                    <PersonalData cb={onSubmitPersonalDataForm} form={personalDataForm}
                                  selectedShipment={selectedShipment}
                                  setSelectedShipment={setSelectedShipment}/>
                </TabPanel>
                <TabPanel className={styles.tabPanel} forceRender={true}>
                    <ShipmentData cb={onSubmitShipmentDataForm} form={shipmentDataForm} shipment={selectedShipment}/>
                </TabPanel>
                {/*<TabPanel className={styles.tabPanel}>*/}
                {/*    <PaymentData cb={onSubmitPaymentDataForm} form={paymentDataForm}/>*/}
                {/*</TabPanel>*/}
                <TabPanel className={styles.tabPanel}>
                    <ConfirmData cb={setTabIndex} values={getFormValues()}/>
                </TabPanel>
            </Tabs>
        </div>
    );
};

export default Checkout;
