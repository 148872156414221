import {FC, useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {toast} from 'react-toastify';
import Modal from '../../Modal';
import FormInput from '../../FormInput';
import Loading from '../../Loading';
import $api from '../../../http';
import {CurrencyModalProps, CurrencyModalForm, TRates} from './types';
import styles from './CurrencyModal.module.scss';

const CurrencyModal: FC<CurrencyModalProps> = ({isOpen, onClose, rates}) => {
    const {
        handleSubmit,
        setError,
        control,
        formState: {errors},
    } = useForm<CurrencyModalForm>();
    const [loading, setLoading] = useState(false);

    const onSubmit = async (data: CurrencyModalForm) => {
        if (isNaN(Number(data.usd))) {
            setError('usd', {type: 'validate', message: 'Неверное значение'});
            return;
        }
        if (isNaN(Number(data.eur))) {
            setError('eur', {type: 'validate', message: 'Неверное значение'});
            return;
        }
        setLoading(true);
        try {
            const res = await $api.post<{ rates: TRates }>('/api/exchange', {
                eur: Number(data.eur),
                usd: Number(data.usd),
            });
            if (res.data.rates) {
                toast.success('Курс успешно изменен');
                onClose(res.data.rates);
            } else {
                toast.error('При обновлении данных произошла ошибка');
            }
        } catch (err) {
            console.log(err);
            toast.error('При обновлении данных произошла ошибка');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal onCloseModal={onClose} opened={isOpen} className={styles.modal}>
            {loading && <Loading modal absolute/>}
            <form onSubmit={handleSubmit(onSubmit)}>
                <h2>Курс валюты</h2>
                <div className={styles.content}>
                    <Controller
                        name="usd"
                        defaultValue={rates?.usd.toString()}
                        control={control}
                        rules={{required: 'Обязательное поле'}}
                        render={({field}) => (
                            <FormInput
                                className={styles.input}
                                value={field.value}
                                setValue={field.onChange}
                                errorMessage={errors.usd?.message}
                                placeholder="Введите курс доллара"
                                label="USD"
                                type="number"
                            />
                        )}
                    />
                    <Controller
                        name="eur"
                        defaultValue={rates?.eur.toString()}
                        control={control}
                        rules={{required: 'Обязательное поле'}}
                        render={({field}) => (
                            <FormInput
                                className={styles.input}
                                value={field.value}
                                setValue={field.onChange}
                                errorMessage={errors.eur?.message}
                                placeholder="Введите курс евро"
                                label="EUR"
                                type="number"
                            />
                        )}
                    />
                    <div className={styles.btnWrapper}>
                        <button className="btn">Сохранить</button>
                    </div>
                </div>
            </form>
        </Modal>
    );
};

export default CurrencyModal;
