import { FC } from 'react';
import styles from './Ads.module.scss';
import { AdsProps } from './types';
import Modal from '../../Modal';
import cn from 'classnames';

const Ads: FC<AdsProps> = ({ isOpen, onClose, text, title }) => {
    return (
        <Modal onCloseModal={onClose} opened={isOpen} className={styles.modal}>
            <h2>{title}</h2>
            <div className={cn(styles.content, 'ql-editor')} dangerouslySetInnerHTML={{ __html: text }} />
        </Modal>
    );
};

export default Ads;
