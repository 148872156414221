import styles from './Table.module.scss';
import cn from 'classnames';
import {FC, useCallback, useState} from 'react';
import {ITable, ITableItem} from './types';
import {ReactComponent as CheckBoxCheckedSVG} from '../../assets/svg/checkbox-checked.svg';
import {ReactComponent as CheckBoxSVG} from '../../assets/svg/checkbox-unchecked.svg';

const Table: FC<ITable> = ({headers, selectable, cb, className, children, fontSize, ...props}) => {
    const defaultField = headers.some(header => header && typeof header !== 'string') ? (headers.find(header => header && typeof header !== 'string' && header.isDefault) as ITableItem | undefined)?.field || '' : ''
    const [sortedBy, setSortedBy] = useState<{ field: string, reverse: boolean }>({field: defaultField, reverse: false})

    const sortHandler = useCallback((field: string, isReversed?: boolean) => {
        const checkSort = (prevState: typeof sortedBy) => {
            if (field === prevState?.field && !prevState.reverse && isReversed)
                return {field, reverse: true}
            else if (field !== prevState?.field)
                return {field, reverse: false}
            else
                return {field: defaultField, reverse: false}
        }
        setSortedBy(checkSort)
        const {field: resField, reverse} = checkSort(sortedBy)
        cb?.(resField, reverse)
    }, [cb, sortedBy])

    return (
        <div className={cn(styles.table, className)} {...props} style={{fontSize: fontSize ? `${fontSize}px` : '16px'}}>
            <div className={styles.headers} style={{fontSize: fontSize ? `${fontSize + 2}px` : '18px'}}>
                {selectable && <>
                    {selectable.show
                        ? <CheckBoxCheckedSVG onClick={selectable.unselectAll}/>
                        : <CheckBoxSVG onClick={selectable.selectAll}/>}
                </>
                }
                {headers.map(item => item !== null && (typeof item === 'string'
                    ? <div key={item}>{item}</div> :
                    <div key={item.name}
                         className={cn(styles.sortable, {
                             [styles.sorted]: sortedBy.field === item.field,
                             [styles.reversed]: sortedBy.reverse && item.isReversed
                         })}
                         onClick={() => sortHandler(item.field, item.isReversed)}>{item.name}</div>))}
            </div>
            {children}
        </div>
    );
};

export default Table;
