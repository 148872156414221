import {FC, useEffect, useState} from 'react';
import styles from './Header.module.scss';
import {Link, useNavigate} from 'react-router-dom';
import cn from 'classnames';
import {useAppDispatch, useAppSelector} from '../../../store/hook';
import {logoutUser} from '../../../store/user/thunks';
import CurrencyModal from '../CurrencyModal';
import {TRates} from '../CurrencyModal/types';
import $api from '../../../http';
import NotificationIcon from '../NotificationIcon';
import MarginModal from "../MarginModal";
import {TMargin} from "../MarginModal/types";
import {ReactComponent as LogoutSVG} from "../../../assets/svg/logout_admin.svg";
import {ReactComponent as WebsiteSVG} from "../../../assets/svg/website.svg";

const Header: FC = () => {
    const {user, notifications} = useAppSelector(state => state.userSlice);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [isCurrencyOpen, setIsCurrencyOpen] = useState(false);
    const [isMarginOpen, setIsMarginOpen] = useState(false);
    const [rates, setRates] = useState<TRates | undefined>();
    const [margins, setMargins] = useState<TMargin | undefined>()

    const logout = () => {
        dispatch(logoutUser());
        navigate('/', {replace: true});
    };

    const currencyModalHandler = () => {
        setIsCurrencyOpen(true);
    };

    const marginModalHandler = () => {
        setIsMarginOpen(true);
    };

    const closeModalHandler = (newRates?: TRates) => {
        setIsCurrencyOpen(false);
        if (newRates) {
            setRates(newRates);
        }
    };

    const closeMarginHandler = (newMargins?: TMargin) => {
        setIsMarginOpen(false);
        if (newMargins) {
            setMargins(newMargins);
        }
    };

    useEffect(() => {

        $api.get<{ rates: TRates }>('/api/exchange')
            .then(res => setRates(res.data.rates))
            .catch(err => console.log('Failed to fetch exchange rates:', err))

        $api.get<{ margins: TMargin }>('/api/margin')
            .then(res => setMargins(res.data.margins))
            .catch(err => console.log('Failed to fetch user margins:', err))
    }, []);

    return (
        <header className={styles.header}>
            <div className={styles.greeting}>Добро пожаловать, <span>{`${user.firstName} ${user.lastName}`}</span></div>
            <nav className={styles.nav}>
                <NotificationIcon messages={notifications}/>
                <button className={cn('btn', styles.navActionBtn)} onClick={marginModalHandler} >Наценки</button>
                <button className={cn('btn', styles.navActionBtn)} onClick={currencyModalHandler}>Курс валюты</button>
                <Link className={styles.navBtn} to="/" target="_blank" title="Перейти на сайт">
                    <WebsiteSVG/>
                </Link>
                <div title="Выйти из аккаунта">
                    <LogoutSVG onClick={logout} className={styles.logout}/>
                </div>
            </nav>
            {isCurrencyOpen && <CurrencyModal rates={rates} isOpen={isCurrencyOpen} onClose={closeModalHandler}/>}
            {isMarginOpen && <MarginModal margins={margins} isOpen={isMarginOpen} onClose={closeMarginHandler}/>}
        </header>
    );
};

export default Header;
