import {ActionReducerMapBuilder} from '@reduxjs/toolkit';
import {TUserState} from './types';
import EStatus from '../../utils/EStatus';
import {IUser} from '../../models/IUser';
import {toast} from 'react-toastify';
import {t} from 'i18next';
import {validateLogin, validateRegister} from '../../utils/extensions';
import {
    checkUserAuth,
    fetchUserShipment, getNotification,
    loginUser,
    logoutUser,
    registerUser, removeNotification,
    setUserShipment,
    updateUser,
} from './thunks';

type TBuilder = ActionReducerMapBuilder<TUserState>
export const loginUserBuilder = (builder: TBuilder) => {
    builder.addCase(loginUser.pending, state => {
        state.status = EStatus.LOADING;
        state.isAuth = false;
        state.user = {} as IUser;
        state.shipment = [];
    });
    builder.addCase(loginUser.fulfilled, (state, {payload}) => {
        state.status = EStatus.SUCCESS;
        state.isAuth = true;
        state.user = payload.user;
        state.shipment = payload.shipment;
        localStorage.setItem('token', payload.accessToken);
        toast.success(t('toasts.auth.success'));
    });
    builder.addCase(loginUser.rejected, (state, {payload}) => {
        state.status = EStatus.ERROR;
        state.isAuth = false;
        state.user = {} as IUser;
        state.shipment = [];
        validateLogin(payload?.errors);
    });
};

export const registerUserBuilder = (builder: TBuilder) => {
    builder.addCase(registerUser.pending, state => {
        state.status = EStatus.LOADING;
        state.isAuth = false;
        state.user = {} as IUser;
        state.shipment = []
    });
    builder.addCase(registerUser.fulfilled, (state, {payload}) => {
        state.status = EStatus.SUCCESS;
        state.isAuth = true;
        state.user = payload.user;
        state.shipment = payload.shipment
        localStorage.setItem('token', payload.accessToken);
        toast.success(t('toasts.auth.register'));
    });
    builder.addCase(registerUser.rejected, (state, {payload}) => {
        state.status = EStatus.ERROR;
        state.isAuth = false;
        state.user = {} as IUser;
        state.shipment = []
        validateRegister(payload?.errors);
    });
};

export const logoutUserBuilder = (builder: TBuilder) => {
    builder.addCase(logoutUser.pending, state => {
        state.status = EStatus.LOADING;
        state.isAuth = false;
        state.user = {} as IUser;
        state.shipment = [];
    });
    builder.addCase(logoutUser.fulfilled, state => {
        state.status = EStatus.SUCCESS;
        state.isAuth = false;
        state.user = {} as IUser;
        state.shipment = [];
        localStorage.removeItem('token');
    });
    builder.addCase(logoutUser.rejected, state => {
        state.status = EStatus.ERROR;
        state.isAuth = false;
        state.user = {} as IUser;
        state.shipment = [];
        localStorage.removeItem('token');
    });
};

export const updateUserBuilder = (builder: TBuilder) => {
    builder.addCase(updateUser.pending, (state) => {
        state.status = EStatus.LOADING
    });
    builder.addCase(updateUser.fulfilled, (state, {payload}) => {
        state.status = EStatus.SUCCESS;
        state.isAuth = true;
        state.user = payload.user;
        toast.success(t('toasts.update_success'));
    });
    builder.addCase(updateUser.rejected, (state) => {
        state.status = EStatus.SUCCESS
        toast.error(t('toasts.error'));
    });
};

export const checkUserAuthBuilder = (builder: TBuilder) => {
    builder.addCase(checkUserAuth.pending, state => {
        state.status = EStatus.LOADING;
        state.isAuth = false;
        state.user = {} as IUser;
    });
    builder.addCase(checkUserAuth.fulfilled, (state, {payload}) => {
        state.status = EStatus.SUCCESS;
        state.isAuth = true;
        state.user = payload.user;
        localStorage.setItem('token', payload.accessToken);
    });
    builder.addCase(checkUserAuth.rejected, state => {
        state.status = EStatus.ERROR;
        state.isAuth = false;
        state.user = {} as IUser;
        state.shipment = []
        localStorage.removeItem('token')
    });
};

export const fetchUserShipmentBuilder = (builder: TBuilder) => {
    builder.addCase(fetchUserShipment.pending, state => {
        state.shipment = [];
    });
    builder.addCase(fetchUserShipment.fulfilled, (state, {payload}) => {
        state.shipment = payload;
    });
};

export const setUserShipmentBuilder = (builder: TBuilder) => {
    builder.addCase(setUserShipment.fulfilled, (state, {payload}) => {
        state.shipment = payload;
        toast.success(t('toasts.update_success'));
    });
    builder.addCase(setUserShipment.rejected, state => {
        state.shipment = [];
        toast.error(t('toasts.error'));
    });
};


export const getNotificationBuilder = (builder: TBuilder) => {
    builder.addCase(getNotification.fulfilled, (state, {payload}) => {
        state.notifications = payload;
    });
    builder.addCase(getNotification.rejected, state => {
        state.shipment = [];
    });
};

export const removeNotificationBuilder = (builder: TBuilder) => {
    builder.addCase(removeNotification.fulfilled, (state, {payload}) => {
        if (payload === -1) {
            state.notifications = []
            return
        }
        state.notifications = state.notifications.filter(item => item.id !== payload);
    });
};
