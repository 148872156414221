import React, {FC, useEffect} from 'react';
import {PersonalDataProps} from './types';
import styles from '../../Checkout.module.scss';
import cn from 'classnames';
import {Controller, SubmitHandler} from 'react-hook-form';
import InputMask from 'react-input-mask';
import {useAppSelector} from '../../../../../store/hook';
import {useTranslation} from 'react-i18next';
import {TPersonalDataForm} from '../../types';
import {TUserShipmentData} from "../../../../../models/IUser";

const PersonalData: FC<PersonalDataProps> = ({
                                                 setSelectedShipment,
                                                 selectedShipment,
                                                 form: {
                                                     register,
                                                     control,
                                                     setValue,
                                                     setError,
                                                     handleSubmit,
                                                     formState: {errors},
                                                 }, cb,
                                             }) => {
    const {user, shipment} = useAppSelector(state => state.userSlice);
    const {t} = useTranslation();

    const shipmentHandler = (data: TUserShipmentData) => {
        return () => {
            setSelectedShipment(data)
        }
    }

    const validateContacts: SubmitHandler<TPersonalDataForm> = (data) => {
        const {firstName, lastName, phone} = data;
        if (!firstName)
            setError('firstName', {type: 'required'});
        if (!lastName)
            setError('lastName', {type: 'required'});
        if (!phone)
            setError('phone', {type: 'required'});
        if (!errors.phone && !errors.firstName && !errors.lastName)
            cb(1, data);
    };

    useEffect(() => {
        if (user && selectedShipment) {
            setValue('firstName', selectedShipment.firstName);
            setValue('middleName', selectedShipment.middleName);
            setValue('lastName', selectedShipment.lastName!);
            setValue('email', user.email);
            setValue('phone', selectedShipment.phone);
        }
    }, [setValue, user, selectedShipment]);
    return (
        <form onSubmit={handleSubmit(validateContacts)}>
            <div className={cn(styles.tabPanelContent, styles.personalData, {[styles.add]: shipment.length > 0})}>
                <h2>{t('checkout.personal_data.subtitle')}</h2>
                <div className={cn(styles.flex, {[styles.invalid]: errors.firstName || errors.middleName})}>
                    <div className={styles.formBlock}>
                        <label htmlFor="firstName">{t('form.name.title')}<sup>*</sup></label>
                        <input type="text" id="firstName" autoComplete="given-name"
                               className={cn(styles.input, {[styles.invalid]: errors.firstName})}
                               defaultValue={selectedShipment?.firstName}
                               placeholder={t('invalid.name.empty') || ''} {...register('firstName', {required: t('invalid.name.empty') || ''})}/>
                        {errors.firstName &&
                            <span className={styles.error}>{errors.firstName.message}</span>}
                    </div>
                    <div className={styles.formBlock}>
                        <label htmlFor="middleName">{t('cabinet.personal_data.middle_name.value')}</label>
                        <input type="text" id="middleName" autoComplete="additional-name"
                               className={cn(styles.input, {[styles.invalid]: errors.middleName})}
                               defaultValue={selectedShipment?.middleName}
                               placeholder={t('cabinet.personal_data.middle_name.placeholder') || ''} {...register('middleName')}/>
                        {errors.middleName &&
                            <span className={styles.error}>{errors.middleName.message}</span>}
                    </div>
                </div>
                <div className={styles.formBlock}>
                    <label
                        htmlFor="lastName">{t('cabinet.personal_data.lastName.value')}<sup>*</sup></label>
                    <input type="text" id="lastName" autoComplete="family-name"
                           className={cn(styles.input, {[styles.invalid]: errors.lastName})}
                           defaultValue={selectedShipment?.lastName}
                           placeholder={t('cabinet.personal_data.lastName.placeholder') || ''} {...register('lastName', {required: t('cabinet.personal_data.lastName.placeholder') || ''})}/>
                    {errors.lastName && <span className={styles.error}>{errors.lastName.message}</span>}
                </div>
                <div className={styles.formBlock}>
                    <label htmlFor="email">Email</label>
                    <input type="text" id="email"
                           className={cn(styles.input, {[styles.invalid]: errors.email})}
                           defaultValue={user.email}
                           placeholder={t('cabinet.contact_data.email.placeholder') || ''} {...register('email')}/>
                    {errors.email && <span className={styles.error}>{errors.email.message}</span>}
                </div>
                <div className={styles.formBlock}>
                    <label htmlFor="phone">{t('cabinet.contact_data.phone')}<sup>*</sup></label>
                    <Controller
                        name="phone"
                        control={control}
                        defaultValue={selectedShipment?.phone}
                        rules={{
                            required: t('invalid.phone.empty') || 'Error',
                            validate: value => value.indexOf('_') === -1 ? true : t('invalid.phone.format') || 'Error',
                        }}
                        render={({field}) => (
                            <InputMask
                                mask="+38 (999)-999-99-99"
                                placeholder="+38 (___)-___-__-__"
                                id="phone"
                                value={field.value}
                                onChange={field.onChange}
                                className={cn(styles.input, {[styles.invalid]: errors.phone})}/>
                        )}
                    />
                    {errors.phone && <span className={styles.error}>{errors.phone.message}</span>}
                </div>
                {shipment.length > 0 && shipment[0].lastName && <div className={cn(styles.formBlock, styles.choices)}>
                    <label className={styles.blockTitle}>{t('checkout.personal_data.saved')}</label>
                    {shipment.map(item => <div key={item.id} className={styles.radioBlock}>
                        <label htmlFor={`${item.lastName} ${item.firstName}`} className={styles.radio}>
                            <input type="radio" id={`${item.lastName} ${item.firstName}`} name="shipment"
                                   onClick={shipmentHandler(item)}
                                   defaultChecked={selectedShipment?.lastName === item.lastName && selectedShipment?.firstName === item.firstName}/>
                            <span>{`${item.lastName} ${item.firstName}`}</span>
                        </label>
                    </div>)}
                </div>}
            </div>
            <div className={styles.btnWrapper}>
                <button className="btn">{t('checkout.next')}</button>
            </div>
        </form>
    );
};

export default PersonalData;
