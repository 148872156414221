import axios, {AxiosError, AxiosRequestConfig} from 'axios';
import {ErrorResponse} from '../models/response/ErrorResponse';
import {AuthResponse} from '../models/response/AuthResponse';

export const API_URL = process.env.REACT_APP_API_URL

const $api = axios.create({
    withCredentials: true,
    baseURL: API_URL,
});
$api.interceptors.request.use((config) => {
    config.headers!.Authorization = `Bearer ${localStorage.getItem('token')}`;
    // config.headers!['Content-Type'] = 'application/json';
    if (config.data instanceof FormData) {
        config.headers!['Content-Type'] = 'multipart/form-data';
    } else {
        config.headers!['Content-Type'] = 'application/json';
    }
    return config;
});

$api.interceptors.response.use(config => config, async (error: AxiosError<ErrorResponse>) => {
    const originalRequest = error.config as AxiosRequestConfig & { _isRetry: boolean };
    // @ts-ignore
    if (error.response?.status === 401 && error.config && !error.config._isRetry) {
        originalRequest._isRetry = true;
        try {
            const response = await axios.get<AuthResponse>(`${API_URL}/api/user/refresh`, {withCredentials: true});
            localStorage.setItem('token', response.data.accessToken);
            return $api.request(originalRequest!);
        } catch (e) {
            console.log(e);
        }
    }
    throw error;
});

export default $api;
