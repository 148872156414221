import {useAppDispatch} from '../store/hook';
import {setUserSliceStatus} from '../store/user/slice';
import {useEffect} from 'react';
import EStatus from '../utils/EStatus';
import {checkUserAuth, fetchUserShipment} from '../store/user/thunks';

function useCheckAuth() {
    const dispatch = useAppDispatch();
    useEffect(() => {
        const token = localStorage.getItem('token')
        if (token) {
            dispatch(checkUserAuth());
            dispatch(fetchUserShipment());
        } else
            dispatch(setUserSliceStatus(EStatus.ERROR));
    }, [dispatch]);
}

export default useCheckAuth;
