import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import './assets/styles/index.scss';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import store from './store/store';
import "./18n"
import Loading from "./components/Loading";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <Suspense fallback={<Loading/>}>
                <App/>
            </Suspense>
        </BrowserRouter>
    </Provider>,
);
