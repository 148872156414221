import {ActionReducerMapBuilder} from '@reduxjs/toolkit';
import EStatus from '../../utils/EStatus';
import {TSupplierState} from './types';
import {fetchSuppliers, editSupplier} from './thunks';
import {toast} from 'react-toastify';

type TBuilder = ActionReducerMapBuilder<TSupplierState>
export const fetchSupplierBuilder = (builder: TBuilder) => {
    builder.addCase(fetchSuppliers.pending, state => {
        state.status = EStatus.LOADING;
        state.suppliers = [];
    });
    builder.addCase(fetchSuppliers.fulfilled, (state, {payload}) => {
        state.status = EStatus.SUCCESS;
        state.suppliers = payload;
    });
    builder.addCase(fetchSuppliers.rejected, state => {
        state.status = EStatus.ERROR;
        state.suppliers = [];
    });
};
export const editSupplierBuilder = (builder: TBuilder) => {
    builder.addCase(editSupplier.fulfilled, (state, {payload}) => {
        state.status = EStatus.SUCCESS;
        state.suppliers = state.suppliers.map(item => item.id === payload.id ? payload : item);
        toast.success(`Прайс ${payload.title} изменен`);
    });
    builder.addCase(editSupplier.rejected, (state, action) => {
        toast.error(action.payload?.message);
    });
};