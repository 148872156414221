import {Manager} from 'socket.io-client';

const manager = new Manager(process.env.REACT_APP_API_URL, {
    autoConnect: false,

    query: {
        token: localStorage.getItem('token')
    },
});

export const socketAdmin = manager.socket('/admin');
export const socketClient = manager.socket('/client');

let reconnectAttempts = 0;

const autoReconnect = () => {
    if (reconnectAttempts < Number(process.env.REACT_APP_MAX_RECONNECT_ATTEMPTS)) {
        manager.open();
        reconnectAttempts++;
    } else {
        // Достигнуто максимальное количество попыток переподключения
        console.log('Не удалось переподключиться к сокету');
    }
};

manager.on('error', () => {
    console.log('Ошибка подключения к сокету');
    autoReconnect();
});

manager.on('reconnect_error', () => {
    console.log('Соединение с сокетом разорвано');
    autoReconnect();
});
