import {Route, Routes} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import useCheckAuth from './hooks/useCheckAuth';
import AuthRequire from './components/AuthRequire';
import Checkout from './pages/client/Checkout';
import {HelmetProvider} from 'react-helmet-async';
import ClientLayout from './layouts/Client';
import AdminLayout from './layouts/Admin';
import {lazy, Suspense} from 'react';
import Loading from './components/Loading';
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import ru from "date-fns/locale/ru";
import uk from "date-fns/locale/uk";
import {createTheme, ThemeProvider} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {useTranslation} from "react-i18next";
import {ruRU, ukUA} from "@mui/x-date-pickers/locales";
import {outlinedInputClasses} from '@mui/material/OutlinedInput';

const Contacts = lazy(() => import('./pages/client/Contacts'));
const News = lazy(() => import('./pages/client/News'));
const FAQ = lazy(() => import('./pages/client/FAQ'));
const Cart = lazy(() => import('./pages/client/Cart'));
const Search = lazy(() => import('./pages/client/Search'));
const Orders = lazy(() => import('./pages/client/Orders'));
const Shipments = lazy(() => import('./pages/client/Shipments'));
const Finances = lazy(() => import('./pages/client/Finances'));
const Messenger = lazy(() => import('./pages/client/Messenger'));
const Cabinet = lazy(() => import('./pages/client/Cabinet'));
const Requests = lazy(() => import('./pages/client/Requests'));
const Recovery = lazy(() => import('./pages/client/Recovery'));


const Announcement = lazy(() => import('./pages/admin/Announcement'));
const Requisites = lazy(() => import('./pages/admin/Requisites'));
const OrdersAdmin = lazy(() => import('./pages/admin/Orders'));
const PricesAdmin = lazy(() => import('./pages/admin/Prices'));
const PriceInfo = lazy(() => import('./pages/admin/Prices/pages/PriceInfo'));
const CreatePrice = lazy(() => import('./pages/admin/Prices/pages/CreatePrice'));
const SuppliersTM = lazy(() => import('./pages/admin/Prices/pages/SuppliersTM'));
const ContactsAdmin = lazy(() => import('./pages/admin/Contacts'));
const ShipmentsAdmin = lazy(() => import('./pages/admin/Shipments'));
const Client = lazy(() => import('./pages/admin/Client'));
const Users = lazy(() => import('./pages/admin/Users'));
const Brands = lazy(() => import('./pages/admin/Brands'));
const BrandGroups = lazy(() => import('./pages/admin/BrandGroups'));
const Invoices = lazy(() => import('./pages/admin/Invoices'));
const PaymentMessages = lazy(() => import('./pages/admin/PaymentMessages'));
const PaymentMessagesInfo = lazy(() => import('./pages/admin/PaymentMessages/pages/PaymentMessagesInfo'));
const UserInfo = lazy(() => import('./pages/admin/Users/pages/UserInfo'));
const Crosses = lazy(() => import('./pages/admin/Crosses'));


const NotFound = lazy(() => import('./pages/NotFound'));

export const CURRENT_DATE = new Date();
export const PREVIOUS_DATE = new Date(CURRENT_DATE.getTime() - 31 * 24 * 3600 * 1000);

function App() {
    const {i18n} = useTranslation();
    const localeText = (i18n.language === 'Ru' ? ruRU : ukUA).components.MuiLocalizationProvider.defaultProps.localeText
    const theme = createTheme({
        typography: {
            fontFamily: 'Montserrat, sans-serif'
        },
        components: {
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        borderColor: '#ACACAC',
                    },
                    root: {
                        [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: '#ACACAC',
                        },
                        [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                            borderColor: '#678099',
                            borderWidth: '1px',
                        },
                    },
                },
            }
        },
        palette: {
            primary: {
                main: '#678099'
            },
        },
    });

    useCheckAuth();
    return (
        <HelmetProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={i18n.language === 'Ru' ? ru : uk}
                                  localeText={localeText}>
                <ThemeProvider theme={theme}>
                    <Routes>
                        <Route element={<ClientLayout/>}>
                            <Route index element={<Suspense fallback={<Loading/>} children={<Contacts/>}/>}/>
                            <Route path="news" element={<Suspense fallback={<Loading/>} children={<News/>}/>}/>
                            <Route path="faq" element={<Suspense fallback={<Loading/>} children={<FAQ/>}/>}/>
                            <Route path="cart" element={<Suspense fallback={<Loading/>} children={<Cart/>}/>}/>
                            <Route path="search" element={<Suspense fallback={<Loading/>} children={<Search/>}/>}/>
                            <Route path="checkout" element={<Suspense fallback={<Loading/>} children={<Checkout/>}/>}/>
                            <Route path="recovery/:token"
                                   element={<Suspense fallback={<Loading/>} children={<Recovery/>}/>}/>
                            <Route element={<AuthRequire/>}>
                                <Route path="orders" element={<Suspense fallback={<Loading/>} children={<Orders/>}/>}/>
                                <Route path="shipments"
                                       element={<Suspense fallback={<Loading/>} children={<Shipments/>}/>}/>
                                <Route path="finances"
                                       element={<Suspense fallback={<Loading/>} children={<Finances/>}/>}/>
                                <Route path="messages"
                                       element={<Suspense fallback={<Loading/>} children={<Messenger/>}/>}/>
                                <Route path="requests"
                                       element={<Suspense fallback={<Loading/>} children={<Requests/>}/>}/>
                                <Route path="cabinet"
                                       element={<Suspense fallback={<Loading/>} children={<Cabinet/>}/>}/>
                            </Route>
                        </Route>
                        <Route path="admin" element={<AdminLayout/>}>
                            <Route index element={<Suspense fallback={<Loading/>} children={<OrdersAdmin/>}/>}/>
                            <Route path="prices">
                                <Route index element={<Suspense fallback={<Loading/>} children={<PricesAdmin/>}/>}/>
                                <Route path=":id">
                                    <Route index element={<Suspense fallback={<Loading/>} children={<PriceInfo/>}/>}/>
                                    <Route path="suppliers"
                                           element={<Suspense fallback={<Loading/>} children={<SuppliersTM/>}/>}/>
                                </Route>
                                <Route path="new"
                                       element={<Suspense fallback={<Loading/>} children={<CreatePrice/>}/>}/>
                            </Route>
                            <Route path="brands" element={<Suspense fallback={<Loading/>} children={<Brands/>}/>}/>
                            <Route path="groups" element={<Suspense fallback={<Loading/>} children={<BrandGroups/>}/>}/>
                            <Route path="requisites"
                                   element={<Suspense fallback={<Loading/>} children={<Requisites/>}/>}/>
                            <Route path="contacts"
                                   element={<Suspense fallback={<Loading/>} children={<ContactsAdmin/>}/>}/>
                            <Route path="payments">
                                <Route index element={<Suspense fallback={<Loading/>} children={<PaymentMessages/>}/>}/>
                                <Route path=":id"
                                       element={<Suspense fallback={<Loading/>} children={<PaymentMessagesInfo/>}/>}/>
                            </Route>
                            <Route path="shipments"
                                   element={<Suspense fallback={<Loading/>} children={<ShipmentsAdmin/>}/>}/>
                            <Route path="invoices" element={<Suspense fallback={<Loading/>} children={<Invoices/>}/>}/>
                            <Route path="users">
                                <Route index element={<Suspense fallback={<Loading/>} children={<Users/>}/>}/>
                                <Route path=":id" element={<Suspense fallback={<Loading/>} children={<UserInfo/>}/>}/>
                            </Route>
                            <Route path="client" element={<Suspense fallback={<Loading/>} children={<Client/>}/>}/>
                            <Route path="announcement"
                                   element={<Suspense fallback={<Loading/>} children={<Announcement/>}/>}/>
                            <Route path="crosses" element={<Suspense fallback={<Loading/>} children={<Crosses/>}/>}/>
                        </Route>
                        <Route path="*" element={<Suspense fallback={<Loading/>} children={<NotFound/>}/>}/>
                    </Routes>
                </ThemeProvider>
            </LocalizationProvider>
        </HelmetProvider>
    );
}

export default App;
