import $api from '../../http';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { IUserAdmin } from '../../models/IUser';
import { TEditUserForm } from '../../pages/admin/Users/pages/UserInfo/types';
import { AxiosError } from 'axios';
import { ErrorResponse } from '../../models/response/ErrorResponse';

export const fetchUsersAdmin = createAsyncThunk<IUserAdmin[], void, {
    rejectValue: string
}>('usersAdmin/fetchUsersAdmin', async (_, {rejectWithValue}) => {
    try {
        const response = await $api.get<{ users: IUserAdmin[] }>('/api/user/admin');
        return response.data.users;
    } catch (e) {
        return rejectWithValue('При загрузке пользователей произошла ошибка');
    }
});
export const editUsersAdmin = createAsyncThunk<IUserAdmin, TEditUserForm & { id: number }, {
    rejectValue: ErrorResponse
}>('usersAdmin/editUsersAdmin', async (user, {rejectWithValue}) => {
    try {
        const response = await $api.put<{ user: IUserAdmin }>(`/api/user/admin/${user.id}`, user);
        return response.data.user;
    } catch (e) {
        const err = e as AxiosError<ErrorResponse>;
        return rejectWithValue(err.response?.data!);
    }
});

export const deleteUserAdmin = createAsyncThunk<{ id: number, success: boolean }, number, {
    rejectValue: ErrorResponse
}>('usersAdmin/deleteUserAdmin', async (id, {rejectWithValue}) => {
    try {
        const response = await $api.delete<{ success: boolean }>(`/api/user/admin/${id}`);
        return {id, success: response.data.success};
    } catch (e) {
        const err = e as AxiosError<ErrorResponse>;
        return rejectWithValue(err.response?.data!);
    }
});
