import styles from './HoverInfo.module.scss';
import {FC, useRef} from 'react';
import {THoverInfo} from './types';
import cn from 'classnames';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';

const HoverInfo: FC<THoverInfo> = ({text, children, copy, position, className, ...props}) => {
    const {t} = useTranslation();
    const ref = useRef<HTMLDivElement>(null);
    const copyHandler = async () => {
        if (ref.current && copy) {
            await navigator.clipboard.writeText(text);
            toast.info(t('toasts.copy'), {
                toastId: 'toast_copy',
                autoClose: 1000,
            });
        }
    };
    return (
        <div className={cn(styles.hoverInfo, className, {[styles.copy]: copy})} ref={ref} {...props}
             onClick={copyHandler}>
            {children}
            <span className={cn(styles.hoverText, {
                [styles.left]: position === 'left',
                [styles.center]: position === 'center',
            })}>{text}</span>
        </div>
    );
};

export default HoverInfo;