import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import EStatus from '../../utils/EStatus';
import {TCartItem, TCartState} from './types';
import {addCartItemsBuilder, getCartBuilder} from './builders';

const initialState: TCartState = {
    status: EStatus.IDLE,
    cart: [],
};

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addToCart(state, {payload}: PayloadAction<TCartItem | TCartItem[]>) {
            state.status = EStatus.SUCCESS
            if (Array.isArray(payload))
                state.cart = payload.concat(state.cart);
            else
                state.cart = [payload, ...state.cart];
        },
        removeFromCart(state, {payload}: PayloadAction<number | number[]>) {
            state.status = EStatus.SUCCESS
            if (typeof payload === 'number')
                state.cart = state.cart.filter(({id}) => id !== payload);
            else
                state.cart = state.cart.filter(({id}) => !payload.includes(id));
        },

        editCartItem(state, {payload}: PayloadAction<TCartItem>) {
            state.status = EStatus.SUCCESS
            state.cart = state.cart.map(item => item.id === payload.id ? payload : item);
        },

        setCart(state, {payload}: PayloadAction<TCartItem[]>) {
            state.status = EStatus.SUCCESS
            state.cart = payload;
        },
    },
    extraReducers: builder => {
        getCartBuilder(builder);
        addCartItemsBuilder(builder);
    },
});

export const {addToCart, removeFromCart, editCartItem, setCart} = cartSlice.actions;

export default cartSlice.reducer;
