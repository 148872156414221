import EStatus from '../../utils/EStatus';
import {addCartItem, getCart} from './thunks';
import {TCartState} from './types';
import {ActionReducerMapBuilder} from '@reduxjs/toolkit';

type TBuilder = ActionReducerMapBuilder<TCartState>
export const getCartBuilder = (builder: TBuilder) => {
    builder.addCase(getCart.pending, state => {
        state.status = EStatus.LOADING;
        state.cart = [];
    });
    builder.addCase(getCart.fulfilled, (state, {payload}) => {
        state.status = EStatus.LOADING;
        state.cart = payload;
    });
    builder.addCase(getCart.rejected, state => {
        state.status = EStatus.ERROR;
        state.cart = [];
    });
};
export const addCartItemsBuilder = (builder: TBuilder) => {
    builder.addCase(addCartItem.pending, state => {
        state.status = EStatus.LOADING;
    });
    builder.addCase(addCartItem.fulfilled, (state, {payload}) => {
        state.status = EStatus.LOADING;
        state.cart = [...payload, ...state.cart];
    });
    builder.addCase(addCartItem.rejected, state => {
        state.status = EStatus.ERROR;
    });
};