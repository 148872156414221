import {FC, useState} from 'react';
import FormInput from '../../FormInput';
import styles from './RecoveryPassword.module.scss';
import {useTranslation} from "react-i18next";
import $api from "../../../http";
import {toast} from "react-toastify";
import Loading from "../../Loading";

const RecoveryPassword: FC<{ cb: () => void }> = ({cb}) => {
    const {t} = useTranslation()
    const [email, setEmail] = useState('')
    const [error, setError] = useState('')
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [loading, setLoading] = useState(false)

    const submitHandler = () => {
        setError('')
        if (!email)
            setError(t('checkout.shipment.required') || '')
        setLoading(true)
        $api.get(`/api/user/recovery/${email}`).then(res => {
            if (res.data.success) {
                setEmail('')
                setIsSubmitted(true)
            } else
                toast.error(t('toasts.error'))
        }).catch(err => {
            console.log(err);
            toast.error(t('toasts.error'))
        }).finally(() => setLoading(false))
    }

    return (
        <div className={styles.recovery}>
            {loading && <Loading modal absolute/>}
            {isSubmitted ? <>
                <h2>{t('form.forgot.response')}</h2>
                <div className={styles.btnWrapper} style={{marginTop: 20}}>
                    <button className="btn" onClick={cb}>{t('cabinet.close_btn')}</button>
                </div>
            </> : <>
                <h2>{t('form.forgot.title')}</h2>
                <p>{t('form.forgot.description')}</p>
                <FormInput label="Email" placeholder="example@email.com" value={email}
                           setValue={val => setEmail(val.toString())} type="text"
                           errorMessage={error}/>
                <div className={styles.btnWrapper}>
                    <button className="btn" onClick={submitHandler}>{t('form.forgot.button')}</button>
                </div>
            </>}
        </div>
    );
};

export default RecoveryPassword;
